<template>
  <dashboard-layout v-slot="userAccess">
    <loader-animation v-if="isLoading" />
    <div
      v-if="userAccess.userAccess.access.isRevoke"
      class="text-red-400 font-bold w-full text-center mb-5"
    >
      <p>
        Seu acesso foi expirado em
        {{ userAccess.userAccess.access.revoke_access_at }}.
      </p>
      <p>
        Entre em contato com o seu sindicato para liberação do seu acesso
        definitivo.
      </p>
      <p>
        Sindicato: {{ userAccess.userAccess.informations.syndicate.name }}.
        Telefone:
        {{
          userAccess.userAccess.informations.syndicate.telephone
            | VMask('(##) ####-####')
        }}
      </p>
    </div>

    <modal-layout>
      <template v-slot:header><h1>Atenção</h1></template>
      <template v-slot:body
        ><p>
          Seu acesso foi expirado! Por favor, entre em contato com seu sindicato
          para solicitar renovação do seu acesso!
          <br /><br />
          Sindicato: {{ userAccess.userAccess.informations.syndicate.name }}.
          <br />
          Telefone:
          {{
            userAccess.userAccess.informations.syndicate.telephone
              | VMask('(##) ####-####')
          }}
        </p></template
      >
    </modal-layout>
    <div class="flex flex-col w-full h-full p-5" v-if="!isLoading">
      <button
        class="bg-green-1000 w-40 p-2 text-white rounded-lg self-end cursor-pointer hover:bg-green-1001"
        type="button"
        @click="newSearch"
      >
        Nova colheita
      </button>
      <div
        v-if="typeof participants.gold !== 'undefined'"
        class="flex flex-col"
      >
        <div class="flex items-center p-2">
          <img :src="require('../../assets/images/gold-badge.png')" alt="" />
          <h2 class="text-2xl font-bold mx-2">Ouro</h2>
          <p class="text-lg">
            - {{ participants.gold.length }} talentos colhidos
          </p>
        </div>
        <div class="hidden md:flex bg-white w-full rounded px-5 py-2 mb-2">
          <div class="w-3/12 px-1">Nome</div>
          <div class="w-2/12 px-1">Cidade</div>
          <div class="w-2/12 px-1">Escolaridade</div>
          <div class="w-2/12 px-1">E-mail</div>
          <div class="w-2/12 px-1">Celular</div>
          <div class="w-1/12 text-center">Currículo</div>
        </div>
        <div
          v-for="participant in participants.gold"
          :key="participant.id"
          class="hidden md:flex md:items-center bg-white w-full rounded px-5 py-2 mb-2 hover:bg-gray-400"
        >
          <div class="w-3/12 px-1">{{ participant.name | capitalize }}</div>
          <div class="w-2/12 px-1">{{ participant.city }}</div>
          <div class="w-2/12 px-1">{{ participant.education_level }}</div>
          <div
            class="w-2/12 px-1 break-all"
            v-tooltip="{
              content:
                'Para visualizar o E-mail do talento acesse o curriculo através do botão Visualizar.',
              placement: 'top'
            }"
          >
            {{ participant.email | filterEmail }}
          </div>
          <the-mask
            class="w-2/12 px-1 bg-transparent focus:outline-none"
            mask="(##) # ####-####"
            v-tooltip="{
              content:
                'Para visualizar o Telefone do talento acesse o curriculo através do botão Visualizar.',
              placement: 'top'
            }"
            :value="participant.cellphone"
            :tokens="anonymousTokens"
          ></the-mask>
          <div class="w-1/12 flex justify-center items-center">
            <button
              class="mr-3 text-sm bg-yellow-500 hover:bg-yellow-700 black py-1 px-2 rounded focus:outline-none focus:shadow-outline"
              @click="
                viewCurriculum(
                  userAccess.userAccess.access.isRevoke,
                  participant.id
                )
              "
            >
              Visualizar
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="typeof participants.silver !== 'undefined'"
        class="flex flex-col"
      >
        <div class="flex items-center p-2">
          <img src="@/assets/images/silver-badge.png" alt="" />
          <h2 class="text-2xl font-bold mx-2">Prata</h2>
          <p class="text-lg">
            - {{ participants.silver.length }} talentos colhidos
          </p>
        </div>
        <div class="flex bg-white w-full rounded px-5 py-2 mb-2">
          <div class="w-3/12 px-1">Nome</div>
          <div class="w-2/12 px-1">Cidade</div>
          <div class="w-2/12 px-1">Escolaridade</div>
          <div class="w-2/12 px-1">E-mail</div>
          <div class="w-2/12 px-1">Celular</div>
          <div class="w-1/12 text-center">Currículo</div>
        </div>
        <div
          v-for="participant in participants.silver"
          :key="participant.id"
          class="flex items-center bg-white w-full rounded px-5 py-2 mb-2 hover:bg-gray-400"
        >
          <div class="w-3/12 px-1">{{ participant.name | capitalize }}</div>
          <div class="w-2/12 px-1">{{ participant.city }}</div>
          <div class="w-2/12 px-1">{{ participant.education_level }}</div>
          <div
            class="w-2/12 px-1 break-all"
            v-tooltip="{
              content:
                'Para visualizar o E-mail do talento acesse o curriculo através do botão Visualizar.',
              placement: 'top'
            }"
          >
            {{ participant.email | filterEmail }}
          </div>
          <the-mask
            class="w-2/12 px-1 bg-transparent focus:outline-none"
            mask="(##) # ####-####"
            v-tooltip="{
              content:
                'Para visualizar o Telefone do talento acesse o curriculo através do botão Visualizar.',
              placement: 'top'
            }"
            :value="participant.cellphone"
            :tokens="anonymousTokens"
          ></the-mask>
          <div class="w-1/12 flex justify-center items-center">
            <button
              class="mr-3 text-sm bg-yellow-500 hover:bg-yellow-700 black py-1 px-2 rounded focus:outline-none focus:shadow-outline"
              @click="
                viewCurriculum(
                  userAccess.userAccess.access.isRevoke,
                  participant.id
                )
              "
            >
              Visualizar
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="typeof participants.bronze !== 'undefined'"
        class="flex flex-col"
      >
        <div class="flex items-center p-2">
          <img src="@/assets/images/bronze-badge.png" alt="" />
          <h2 class="text-2xl font-bold mx-2">Bronze</h2>
          <p class="text-lg">
            - {{ participants.bronze.length }} talentos colhidos
          </p>
        </div>
        <div class="flex bg-white w-full rounded px-5 py-2 mb-2">
          <div class="w-3/12 px-1">Nome</div>
          <div class="w-2/12 px-1">Cidade</div>
          <div class="w-2/12 px-1">Escolaridade</div>
          <div class="w-2/12 px-1">E-mail</div>
          <div class="w-2/12 px-1">Celular</div>
          <div class="w-1/12 text-center">Currículo</div>
        </div>
        <div
          v-for="participant in participants.bronze"
          :key="participant.id"
          class="flex items-center bg-white w-full rounded px-5 py-2 mb-2 hover:bg-gray-400"
        >
          <div class="w-3/12 px-1">{{ participant.name | capitalize }}</div>
          <div class="w-2/12 px-1">{{ participant.city }}</div>
          <div class="w-2/12 px-1">{{ participant.education_level }}</div>
          <div
            class="w-2/12 px-1 break-all"
            v-tooltip="{
              content:
                'Para visualizar o E-mail do talento acesse o curriculo através do botão Visualizar.',
              placement: 'top'
            }"
          >
            {{ participant.email | filterEmail }}
          </div>
          <the-mask
            class="w-2/12 px-1 bg-transparent focus:outline-none"
            mask="(##) # ####-####"
            v-tooltip="{
              content:
                'Para visualizar o Telefone do talento acesse o curriculo através do botão Visualizar.',
              placement: 'top'
            }"
            :value="participant.cellphone"
            :tokens="anonymousTokens"
          ></the-mask>
          <div class="w-1/12 flex justify-center items-center">
            <button
              class="mr-3 text-sm bg-yellow-500 hover:bg-yellow-700 black py-1 px-2 rounded focus:outline-none focus:shadow-outline"
              @click="
                viewCurriculum(
                  userAccess.userAccess.access.isRevoke,
                  participant.id
                )
              "
            >
              Visualizar
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="
          typeof participants.gold == 'undefined' &&
            typeof participants.silver == 'undefined' &&
            typeof participants.bronze == 'undefined'
        "
        class="flex flex-col"
      >
        <div class="flex bg-white w-full rounded px-5 py-2 mb-2">
          Nenhum talento encontrado
        </div>
      </div>
      <div class="flex justify-end">
        <button class="py-5" @click="viewOtherResults">
          Ver mais talentos
        </button>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '../../layouts/DashboardLayout';
import store from '@/store';
import axios from '@/utils/axios';
import LoaderAnimation from '../../components/LoaderAnimation.vue';
import { TheMask } from 'vue-the-mask';
import ModalLayout from '../../components/Modal';

export default {
  name: 'SearchResults',

  title() {
    return `${process.env.VUE_APP_NAME} | Resultados`;
  },

  data() {
    return {
      participants: {
        gold: [],
        silver: [],
        bronze: []
      },
      isLoading: true,
      anonymousTokens: {
        '#': { pattern: /[\d*]/ }
      }
    };
  },
  components: {
    DashboardLayout,
    LoaderAnimation,
    TheMask,
    ModalLayout
  },
  methods: {
    getParticipants: async function() {
      const response = await axios.post('/api/busca', {
        search: store.state.search,
        cities: store.state.cities.map(city => city.id),
        // course: store.state.course,
        Experience: store.state.Experience,
        brand: store.state.brand,
        education_level: store.state.education_level,
        gender: store.state.gender,
        min_age: store.state.min_age,
        max_age: store.state.max_age,
        participant_name: store.state.participant_name,
        year: store.state.year,
        complementary_curriculum: store.state.complementary_curriculum
      });

      if (response.data[0]) {
        this.participants.gold = response.data[0];
      }

      if (response.data[1]) {
        this.participants.silver = response.data[1];
      }

      if (response.data[2]) {
        this.participants.bronze = response.data[2];
      }

      let participants = {
        gold: this.participants.gold.length
          ? this.participants.gold.map(participant => {
              return participant.id;
            })
          : [],
        silver: this.participants.silver.length
          ? this.participants.silver.map(participant => {
              return participant.id;
            })
          : [],
        bronze: this.participants.bronze.length
          ? this.participants.bronze.map(participant => {
              return participant.id;
            })
          : []
      };

      localStorage.setItem(
        'selectedParticipants',
        JSON.stringify(participants)
      );

      this.isLoading = false;
    },
    viewOtherResults() {
      this.$router.push({
        name: 'search-other-results'
      });
    },
    viewCurriculum(isRevoke, id) {
      if (isRevoke) {
        this.$modal.show('modal');
      } else {
        axios.patch('/api/participantes/salvar-visualizacao', {
          participant_id: id
        });
        let route = this.$router.resolve({
          name: 'curriculum',
          params: { id: id }
        });
        window.open(route.href, '_blank');
      }
    },
    newSearch() {
      store.commit('setSearch', '');
      // store.commit('setCourse', '');
      store.commit('setExperience', '');
      store.commit('setBrand', '');
      store.commit('setEducationLevel', '');
      store.commit('setGender', '');
      store.commit('setMinAge', '');
      store.commit('setMaxAge', '');
      store.commit('setCities', '');
      store.commit('setParticipantName', '');
      store.commit('setYear', '');
      store.commit('setComplementaryCurriculum', '');

      localStorage.removeItem('searchParams');
      localStorage.removeItem('selectedParticipants');

      this.$router.push({
        name: 'search'
      });
    }
  },
  filters: {
    filterEmail(email) {
      return email.search('@teste.com.br') > 0 ? '' : email.toLowerCase();
    },
    capitalize(name) {
      if (!name) return '';

      name = name.toString();

      name = name.split(' ');

      name = name
        .map(part => {
          return part.charAt(0).toUpperCase() + part.toLowerCase().slice(1);
        })
        .map(part => {
          return ['e', 'da', 'do', 'de', 'das', 'dos'].indexOf(
            part.toLowerCase()
          ) !== -1
            ? part.toLowerCase()
            : part;
        });

      return name.join(' ');
    }
  },
  created: function() {
    this.getParticipants();
  }
};
</script>

<style scoped></style>
